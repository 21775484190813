import React from 'react';
import {Link} from 'react-router-dom';

function TeamItem(props) {
    return (
        <>
        <li className='team__item'>
            <Link className='team__item__link' to={props.path}>
            <figure className='team__item__pic-wrap' data-category={props.label}>
                <img
                className='team__item__img'
                alt={props.alt}
                src={props.src}
                />
            </figure>
            <div className='team__item__info'>
                <h5 className='team__item__text'>{props.text}</h5>
            </div>
            </Link>
        </li>
        </>
    )
}

export default TeamItem
