import React from 'react';
/*import {Link} from 'react-router-dom';*/
import CardItem from './CardItem';
import './Cards.css';

function CardsBlog() {
    return (
        <div className='cards' >
        <h1>Braublog</h1>
        <h3>Hier bieten wir Einblick in unsere Brauevents</h3>
        <div className='cards__container'>
          <div className='cards__wrapper'>
              <h2>2021</h2>
            <ul className='cards__items'>
              <CardItem
                src='images/IMG_0910.jpg'
                alt='Landbierfoto'
                text='Hier ist unser Landbier'
                label='20.10.2021'
              />
              <CardItem
                 src='images/IMG_0910.jpg'
                 alt='Landbierfoto'
                 text='Hier ist unser Landbier'
                 label='Landbier'
              />
            </ul>
            <h2>2020</h2>
            <ul className='cards__items'>
              <CardItem
                 src='images/IMG_0910.jpg'
                 alt='Landbierfoto'
                 text='Hier ist unser Landbier'
                 label='Landbier'
              />
              <CardItem
                 src='images/IMG_0910.jpg'
                 alt='Landbierfoto'
                 text='Hier ist unser Landbier'
                 label='Landbier'
              />
              <CardItem
                 src='images/IMG_0910.jpg'
                 alt='Landbierfoto'
                 text='Hier ist unser Landbier'
                 label='Landbier'
              />
            </ul>
          </div>
        </div>
      </div>
    )
}

export default CardsBlog
