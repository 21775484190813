import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Beer from './Beer';
import Blog from './Blog';
import Team from './Team';
function Home () {
    return(
        <>
        <HeroSection/>
        <Beer/>
        <div style={{backgroundColor:'rgb(247, 234, 202)'}}><Team/></div>

        
        <Blog/>
        </>
    )
}
export default Home;