import React from 'react';
import '../../App.css';
import CardsBlog from '../CardsBlog';

function Blog () {
    return(
        <>
        <CardsBlog/>

        </>
    )
}
export default Blog;