import React from 'react';
import '../../App.css';

function Login () {
    return(
        <>
        <h1>Login </h1>
        <div className='input-areas'>
            <form>
            <input
                className='footer-input'
                name='email'
                type='email'
                placeholder='Your Email'
                />
            </form>
        </div>
        
        
        </>
    )
}
export default Login;