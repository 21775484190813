import React from 'react';
import './Footer.css';
import ScrollToTopBtn from "../ScrollToTop.js";


import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
    <ScrollToTopBtn />

      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Über uns</h2>
            <Link to='/team'>Team</Link>
            <Link to='/'>Impressum</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Kontaktiere uns</h2>
            <Link to='/'>Kontakt</Link>
            <Link to='/'>Mail</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <a
             target="_blank" rel="noopener noreferrer"
             href='https://www.instagram.com/scullebrew/'
             >Instagram</a>
            <a 
            target="_blank" rel="noopener noreferrer"
            href='https://youtu.be/a9xCMubsUk0'>Youtube</a>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              Scullebrew 
              <i style={{margin:'5px', cursor: 'pointer'}} class='fas fa-beer' />
            </Link>
          </div>
          <small class='website-rights'>Scullebrew © 2021</small>
          <div class='social-icons'>
            <a
              class='social-icon-link instagram'
              target="_blank" rel="noopener noreferrer"
              href='https://www.instagram.com/scullebrew/'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </a>
            <a
            style={{margin:'10px'}}
            target="_blank" rel="noopener noreferrer"
              class='social-icon-link youtube'
              href='https://youtu.be/a9xCMubsUk0'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;