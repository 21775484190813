import React from 'react';
import './Button.css';
import {Link} from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--outline', 'btn--outline--black'];
const SIZES = ['btn--medium', 'btn--large'];


export const Button = ({
    children, 
    type,
    path,
    onClick, 
    buttonStyle, 
    buttonSize 
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle: STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonStyle: SIZES[0];
  
        return(
            <Link to={path} className='btn-moblie'>
                <button 
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={type}
                >
                    {children}
                </button>
            </Link>
        )
};

