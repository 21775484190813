import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Button } from './Button';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick =() => setClick(!click);
    const closeMobileMenu =() => setClick(!click);

    const showButton =() => {
        if(window.innerWidth <= 960){
            setButton(false)
        } else {
            setButton(true)
        }
    }

    useEffect(() => {
        showButton()
    }, [])

    window.addEventListener('resize', showButton);
    return (
        <>
        <div className='outside--navbar'>
        <nav className='navbar'>
            <div className='navbar-container'>
                <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                    Scullebrew  <i style={{margin:'5px'}} className='fas fa-beer'></i>
                </Link>

            <div className='menu-icon' onClick={handleClick}>
                <i className={click ? 'fas fa-times': 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active': 'nav-menu'}>
                
                <li className='nav-item'>
                    <Link  to='/team' className='nav-links' onClick={closeMobileMenu}>
                        Brauerteam
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link  to='/blog' className='nav-links' onClick={closeMobileMenu}>
                        Braublog
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link  to='/beer' className='nav-links' onClick={closeMobileMenu}>
                        Unsere Biere
                    </Link>
                </li>
                <li>
              <Link
                to='/login' className='nav-links-mobile' onClick={closeMobileMenu}
              >
                Einloggen
              </Link>
            </li>
            </ul>
            {button && <Button path='/login' buttonStyle='btn--outline--black' ><i class="far fa-user"></i></Button>}
            </div>
        </nav>
        </div>
        </>
    )
}

export default Navbar
