import React from 'react';
import '../../App.css';
import CardsBeer from '../CardsBeer';



function Beer () {
    return(
        <div className='beer-container'>
        <CardsBeer/>
        </div>

    )
}
export default Beer;