import React from 'react';
import '../../App.css';
import './Team.css';
import TeamItem from '../TeamItem';

function Team () {
    return(
        <>
        <div className='teams'>
        <h1>Unser Team</h1>
        <div className='team__container'>
          <div className='team__wrapper'>
            <ul className='team__items'>
              <TeamItem
                src='images/IMG_0910.jpg'
                alt='Landbierfoto'
                text='Hier ist unser Landbier'
                label='Frank Schimke'
                path='/team'
              />
              <TeamItem
                 src='images/IMG_0910.jpg'
                 alt='Landbierfoto'
                 text='Hier ist unser Landbier'
                 label='Bernd Anielski'
              />
              <TeamItem
                 src='images/IMG_0910.jpg'
                 alt='Landbierfoto'
                 text='Hier ist unser Landbier'
                 label='Dirk Rohwer-Claußen'
              />
              <TeamItem
                 src='images/IMG_0910.jpg'
                 alt='Landbierfoto'
                 text='Hier ist unser Landbier'
                 label='Jakob Claußen'
              />
            </ul>
          </div>
        </div>
      </div>
        </>
    )
}
export default Team;