//import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';

import {HashRouter as Router, Switch, Route} from 'react-router-dom';
import Beer from './components/pages/Beer';
import Team from './components/pages/Team';
import Blog from './components/pages/Blog';
import Login from './components/pages/Login';
import Footer from './components/Footer';

function App() {
  return (
    <>
        <Router>
            <Navbar />
            <Switch>
                <Route path ='/' exact component={Home}/>
                <Route path ='/beer' exact component={Beer}/>
                <Route path ='/team' exact component={Team}/>
                <Route path ='/blog' exact component={Blog}/>
                <Route path ='/login' exact component={Login}/>
            </Switch>
            <Footer/>
        </Router>
    </>
  );
}

export default App;
